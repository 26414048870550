const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const {utils: {pinnedLayerHelper, createSantaTypesDefinitions}, santaTypesDefinitions} = require('santa-components')

const id = 'PINNED_ROOT_AFTER'
const displayName = 'PinnedRootAfter'

const santaTypes = createSantaTypesDefinitions({
    zIndex: PropTypes.number
}, displayName)

/**
 * @class components.PinnedRootAfter
 */
class PinnedRootAfter extends React.Component {
    render() {
        const [pinnedMesh, others] = _.partition(this.props.children, child => {
            const compId = child.props.id
            const layout = this.props.childrenLayout[compId]
            return _.has(layout, 'docked')
        })
        const isMeshComponentPinned = meshComp => _.get(meshComp, 'isFixed', false) === true && _.has(meshComp, 'docked')
        const pinnedMeshComps = _.filter(_.get(this.props, 'meshParams.components', []), isMeshComponentPinned)

        return [
            pinnedLayerHelper.createPinnedLayer({
                id,
                key: id,
                children: pinnedMesh,
                childrenMeshParams: pinnedMeshComps,
                browser: this.props.browser,
                wixTopAdsHeight: this.props.wixTopAdsHeight,
                zIndex: this.props.zIndex
            }),
            ...others
        ]
    }
}

PinnedRootAfter.displayName = displayName
PinnedRootAfter.compType = id
PinnedRootAfter.santaTypesDefinitions = santaTypes
PinnedRootAfter.propTypes = {
    children: PropTypes.node,
    zIndex: santaTypes.zIndex,
    wixTopAdsHeight: santaTypesDefinitions.WixAds.wixTopAdsHeight,
    meshParams: santaTypesDefinitions.Component.meshParams,
    childrenLayout: santaTypesDefinitions.Component.childrenLayout,
    browser: santaTypesDefinitions.Browser.browser
}

module.exports = PinnedRootAfter
