const React = require('react')
const PropTypes = require('prop-types')

const id = 'DEPRECATE_IE_BANNER'

/**
 * @class components.DeprecateInternetExplorerBanner
 */
class DeprecateInternetExplorerBanner extends React.Component {
    render() {
        const hideBanner = () => {
            const element = document.getElementById(id)
            element.style.display = 'none'
        }

        const styles = {
            root: {
                position: 'fixed',
                top: 0,
                width: '100%',
                height: `${this.props.height}px`,
                lineHeight: `${this.props.height}px`,
                fontSize: '14px',
                backgroundColor: '#ffedc1',
                textAlign: 'center',
                zIndex: 2147483647,
                borderBottom: '1px solid black'
            },
            icon: {
                verticalAlign: 'middle',
                paddingRight: '7px'
            },
            contentWrapper: {
                position: 'relative'
            },
            link: {
                textDecoration: 'underline',
                color: 'black'
            },
            closeButton: {
                position: 'absolute',
                right: '20px',
                cursor: 'pointer'
            }
        }

        return (
            <div id={id} key={id} style={styles.root}>
                <div style={styles.contentWrapper}>
                    <span style={styles.icon}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15">
                            <path fillRule="evenodd" d="M391.49701,6 C392.13801,6 392.77801,6.325 393.16501,6.933 L393.16501,6.933 L399.81201,18.499 C400.25501,19.146 399.89801,20.046 398.88301,20.046 L398.88301,20.046 L384.11101,20.046 C383.12601,20.046 382.73901,19.151 383.18201,18.504 L383.18201,18.504 L389.82901,6.9 C390.21601,6.293 390.85601,6 391.49701,6 Z M391.99701,16.018 L391.00501,16.018 L390.99701,17.046 L391.99701,17.046 L391.99701,16.018 Z M391.99701,11.046 L391.00501,11.046 L390.99701,15.046 L391.39801,15.046 L391.99701,14.046 L391.99701,11.046 Z" transform="translate(-383 -6)"/>
                        </svg>
                    </span>
                    <span>{this.props.bannerText} </span>
                    <a href={`https://${this.props.language}.wix.com/outdated-browser/internet-explorer`} target="_blank" rel="noopener noreferrer" style={styles.link}>{this.props.linkText}</a>
                    <span style={styles.closeButton} onClick={hideBanner}>&#10005;</span>
                </div>
            </div>
        )
    }
}

DeprecateInternetExplorerBanner.compType = id
DeprecateInternetExplorerBanner.displayName = 'DeprecateInternetExplorerBanner'

DeprecateInternetExplorerBanner.propTypes = {
    height: PropTypes.number,
    language: PropTypes.string,
    bannerText: PropTypes.string,
    linkText: PropTypes.string
}

module.exports = DeprecateInternetExplorerBanner
