(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("prop-types"), require("santa-components"), require("lodash"), require("santa-core-utils"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "prop-types", "santa-components", "lodash", "santa-core-utils"], factory);
	else if(typeof exports === 'object')
		exports["bolt-components"] = factory(require("react"), require("prop-types"), require("santa-components"), require("lodash"), require("santa-core-utils"));
	else
		root["bolt-components"] = factory(root["react"], root["prop-types"], root["santa-components"], root["lodash"], root["santa-core-utils"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__6__) {
return 