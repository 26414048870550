const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const {utils: {pinnedLayerHelper, createSantaTypesDefinitions}, santaTypesDefinitions} = require('santa-components')

const id = 'PINNED_ROOT_BEFORE_PAGES_CONTAINER'
const displayName = 'PinnedRootBeforePagesContainer'

const santaTypes = createSantaTypesDefinitions({
    zIndex: PropTypes.number
}, displayName)

/**
 * @class components.PinnedRootBeforePagesContainer
 */
class PinnedRootBeforePagesContainer extends React.Component {
    render() {
        const isMeshComponentPinned = meshComp => _.get(meshComp, 'isFixed', false) === true && _.has(meshComp, 'docked')
        const pinnedMeshComps = _.filter(_.get(this.props, 'meshParams.components', []), isMeshComponentPinned)

        return pinnedLayerHelper.createPinnedLayer({
            id,
            key: id,
            children: this.props.children,
            childrenMeshParams: pinnedMeshComps,
            browser: this.props.browser,
            wixTopAdsHeight: this.props.wixTopAdsHeight,
            zIndex: this.props.zIndex
        })
    }
}

PinnedRootBeforePagesContainer.displayName = displayName
PinnedRootBeforePagesContainer.compType = id
PinnedRootBeforePagesContainer.santaTypesDefinitions = santaTypes
PinnedRootBeforePagesContainer.propTypes = {
    children: PropTypes.node,
    zIndex: santaTypes.zIndex,
    wixTopAdsHeight: santaTypesDefinitions.WixAds.wixTopAdsHeight,
    meshParams: santaTypesDefinitions.Component.meshParams,
    browser: santaTypesDefinitions.Browser.browser
}

module.exports = PinnedRootBeforePagesContainer
