const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const {utils: {pinnedLayerHelper, createSantaTypesDefinitions, masterPageUtils}, santaTypesDefinitions} = require('santa-components')
const {structuralChildrenEnhancerMap} = masterPageUtils

const id = 'PINNED_ROOT_BEFORE_FOOTER'
const displayName = 'PinnedRootBeforeFooter'
const STRUCTURAL_IDS = ['SITE_FOOTER', 'BACK_TO_TOP_BUTTON', 'QUICK_ACTION_BAR']

const santaTypes = createSantaTypesDefinitions({
    zIndex: PropTypes.number
}, displayName)

/**
 * @class components.PinnedRootBeforeFooter
 */
class PinnedRootBeforeFooter extends React.Component {
    render() {
        const [structural, pinned] = _.partition(this.props.children, child => STRUCTURAL_IDS.includes(child.props.id))
        const isMeshComponentPinned = meshComp => _.get(meshComp, 'isFixed', false) === true && _.has(meshComp, 'docked')
        const pinnedMeshComps = _.filter(_.get(this.props, 'meshParams.components', []), isMeshComponentPinned)

        const {childrenLayout, isInSSR, isCurrentPageLandingPage, siteWidth} = this.props

        const structuralChildren = structural.map(child => {
            const compId = child.props.id

            if (compId in structuralChildrenEnhancerMap) {
                return structuralChildrenEnhancerMap[compId](
                    child,
                    childrenLayout[compId] || {},
                    {
                        compId,
                        isInSSR,
                        isCurrentPageLandingPage,
                        siteWidth,
                        isPinnedRootExp: true,
                        isPinnedRoot: true
                    }
                )
            }

            return child
        })

        return [
            pinnedLayerHelper.createPinnedLayer({
                id,
                key: id,
                children: pinned,
                childrenMeshParams: pinnedMeshComps,
                browser: this.props.browser,
                wixTopAdsHeight: this.props.wixTopAdsHeight,
                zIndex: this.props.zIndex
            }),
            ...structuralChildren
        ]
    }
}

PinnedRootBeforeFooter.displayName = displayName
PinnedRootBeforeFooter.compType = id
PinnedRootBeforeFooter.santaTypesDefinitions = santaTypes
PinnedRootBeforeFooter.propTypes = {
    children: PropTypes.node,
    zIndex: santaTypes.zIndex,
    wixTopAdsHeight: santaTypesDefinitions.WixAds.wixTopAdsHeight,
    meshParams: santaTypesDefinitions.Component.meshParams,
    browser: santaTypesDefinitions.Browser.browser,
    childrenLayout: santaTypesDefinitions.Component.childrenLayout,
    isInSSR: santaTypesDefinitions.isInSSR,
    isCurrentPageLandingPage: santaTypesDefinitions.isCurrentPageLandingPage,
    siteWidth: santaTypesDefinitions.siteWidth
}

module.exports = PinnedRootBeforeFooter
