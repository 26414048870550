const React = require('react')
const PropTypes = require('prop-types')
const _ = require('lodash')

const id = 'MAX_Z_INDEX_COMPONENT'

/**
 * @class components.MaxZIndexComponent
 */
class MaxZIndexComponent extends React.Component {
    constructor(props) {
        super(props)
        this.lastComps = props.compsToShowOnTop.slice()
    }
    componentWillReceiveProps(nextProps) {
        _(this.lastComps)
            .xor(nextProps.compsToShowOnTop)
            .forEach(compId => this.props.registerRelayoutPending(compId))
        this.lastComps = nextProps.compsToShowOnTop.slice()
    }
    render() {
        const {css} = this.props
        return (
            <style id={id}>{css}</style>
        )
    }
}

MaxZIndexComponent.compType = id
MaxZIndexComponent.displayName = 'MaxZIndexComponent'

MaxZIndexComponent.propTypes = {
    css: PropTypes.string,
    compsToShowOnTop: PropTypes.arrayOf(PropTypes.string),
    registerRelayoutPending: PropTypes.func
}

module.exports = MaxZIndexComponent
