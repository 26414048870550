const React = require('react')
const PropTypes = require('prop-types')

const id = 'SKIP_TO_CONTENT_BTN'

/**
 * @class components.SkipToContentButton
 */
class SkipToContentButton extends React.Component {
    render() {
        const scrollToMain = () => {
            const mainEl = document.getElementById('PAGES_CONTAINER')
            mainEl.focus()
        }

        return (
            <button id={id} key={id} className="skip-to-content-button has-custom-focus" tabIndex="0" onClick={scrollToMain}>
                {this.props.buttonText}
            </button>
        )
    }
}

SkipToContentButton.compType = id
SkipToContentButton.displayName = 'SkipToContentButton'

SkipToContentButton.propTypes = {
    buttonText: PropTypes.string
}

module.exports = SkipToContentButton
