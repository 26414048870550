const React = require('react')
const {santaTypesDefinitions} = require('santa-components')

const compType = 'GHOST_REF_COMP'

const collapsedStyle = {
    visibility: 'hidden',
    overflow: 'hidden',
    width: 0,
    minWidth: 0,
    height: 0,
    minHeight: 0,
    marginBottom: 0,
    pointerEvents: 'none'
}
/**
 * @class components.GhostRefComp
 */
class GhostRefComp extends React.Component {
    render() {
        const {id, isCollapsed} = this.props
        return (
            <div id={id} data-collapsed={isCollapsed}
            style={collapsedStyle}>
            </div>
        )
    }
}

GhostRefComp.compType = compType
GhostRefComp.propTypes = {
    id: santaTypesDefinitions.Component.id,
    isCollapsed: santaTypesDefinitions.Component.isCollapsed
}
module.exports = GhostRefComp
