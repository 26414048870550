const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const {utils: {pinnedLayerHelper}, santaTypesDefinitions} = require('santa-components')

const id = 'PINNED_ROOT_MAIN'
const displayName = 'PinnedRootMain'

/**
 * @class components.PinnedRootMain
 */
class PinnedRootMain extends React.Component {
    render() {
        const isMeshComponentPinned = meshComp => _.get(meshComp, 'isFixed', false) === true && _.has(meshComp, 'docked')
        const pinnedMeshComps = _.filter(_.get(this.props, 'meshParams.components', []), isMeshComponentPinned)

        return pinnedLayerHelper.createPinnedLayer({
            id,
            key: id,
            children: this.props.children,
            childrenMeshParams: pinnedMeshComps,
            browser: this.props.browser,
            wixTopAdsHeight: this.props.wixTopAdsHeight
        })
    }
}

PinnedRootMain.displayName = displayName
PinnedRootMain.compType = id
PinnedRootMain.propTypes = {
    children: PropTypes.node,
    wixTopAdsHeight: santaTypesDefinitions.WixAds.wixTopAdsHeight,
    meshParams: santaTypesDefinitions.Component.meshParams,
    browser: santaTypesDefinitions.Browser.browser
}

module.exports = PinnedRootMain
